.contact-wrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.contact-left-wrapper {
  width: 50vw;
  margin-left: 2vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.contact-right-wrapper {
  width: 40vw;
  margin-left: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  
}

.contact-quote {
  color: #606C38;
  font-size: 36px;
  font-weight: 100;
  margin: 0;
}

.contact-call {
  text-decoration: none;
  color: white;
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: 450px;

  input{
    background-color: #DDA15ECB;
    border: none;
    height: 40px;
    font-size: 24px;
    color: #606C38;
    padding-left: 10px;
  }
  textarea{
    background-color: #DDA15ECB;
    border: none;
    height: 250px;
    font-size: 24px;
    color: #606C38;
    padding: 10px;
  }
}

.form-label {
  margin-bottom: 10px;
  margin-top: 15px;
  font-size: 24px;
  color: #606C38;
}

.contact-form-btn-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
}

.grecaptcha-badge {
  bottom: 65px !important; 
}

.loader-wrapper {
  top: 0;
  left: 0;
  background-color: #606c38;
  display:flex;
  justify-content: center;
  align-items: center;
}
.loader {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 4px solid #fefae0;
  animation: loader 2s infinite ease;
}
.loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #fefae0;
  animation: loader-inner 2s infinite ease-in;
}
@keyframes loader {
  0% { transform: rotate(0deg);}
  25% { transform: rotate(180deg);}
  50% { transform: rotate(180deg);}
  75% { transform: rotate(360deg);}
  100% { transform: rotate(360deg);}
}
@keyframes loader-inner {
  0% { height: 0%;}
  25% { height: 0%;}
  50% { height: 100%;}
  75% { height: 100%;}
  100% { height: 0%;}
}


.contact-message {
  &-wrapper {
    text-align: center;
  }
  &-success {
    color: #606c38;
  }
  &-fail {
    color: red;
  }
}

@media only screen and (max-width: 1150px){
  .contact-quote {
    font-size: 26px;
  }
}
@media only screen and (max-width: 1025px){
  .contact-quote {
    font-size: 24px;
  }
  .contact-form {
    width: 400px;
  }
}

@media only screen and (max-width: 900px){
  .contact-wrapper {
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin-top: 100px;
  }
  .contact-left-wrapper {
    width: 90vw;
    margin-bottom: 80px;
    margin-top: 75px;
  }
  .contact-right-wrapper {
    width: 90vw;
    margin-left: 0vw;
  }
  .contact-quote {
    font-size: 24px;
  }
  .contact-form {
    width: 500px;
  }
}

@media only screen and (max-width: 768px){
  .contact-form {
    margin-bottom: 300px;
  }
}

@media only screen and (max-width: 426px){
  .contact-wrapper {
    margin-top: 50px;
  }
  .contact-left-wrapper {
    width: 90vw;
    margin-bottom: 80px;
  }
  .contact-right-wrapper {
    width: 90vw;
    margin-left: 0vw;
  }
  .contact-quote {
    font-size: 16px;
  }
  .contact-form {
    width: 300px;
    margin-bottom: 100px;
  }
}