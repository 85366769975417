.home-hero-section {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  height: calc(150vh - 300px);
  img {
    position: absolute;
    right: 0;
    transform: translateY(20%);
    height: 75%;
    filter: opacity(90%);
  }
}

.home-hero-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60vw;
  margin-bottom: 150px;
  z-index: 1;
}
.home-hero-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(254, 250, 224, 0.7);
  padding: 20px;
  h2 {
    margin: 0;
    font-size: clamp(24px, 2.8vw, 44px);
    font-weight: normal;
    color: $site-color-green;
    text-align: center;
  }
}

.carousel-container-layout {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);
}

.carousel-image {
  width: 100%; 
  height: 100%;
}

.carousel-text-container {
  position: absolute;
  left: 23%;
  top: 30%;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 25px;
}

.carousel-text {
  color: white;
  font-size: 45px;
}


@media only screen and (max-width: 1025px){
  .carousel-text-container {
    left: 24%;
    top: 45%;
    padding: 10px;
  }
  .carousel-text {
    font-size: 36px;
  }
}

@media only screen and (max-width: 769px){
  .home-hero-overlay {
    width: 100%;
  }
  .home-hero-text {
    h2 {
      font-size: clamp(28px, 2.8vw, 44px);
    }
  }
  .carousel-text-container {
    left: 21%;
    top: 45%;
    padding: 10px;
  }
  .carousel-text {
    font-size: 26px;
  }
}

@media only screen and (max-width: 426px){
  .home-hero-section {
    height: 100vh;
    img {
      width: 200vw;
      height: auto;
      overflow: hidden;
    }
  }
  .home-hero-overlay {
    width: 100%;
    margin-top: 100px;
  }
  .home-hero-text {
    background-color: rgba(254, 250, 224, 0.8);
    h2 {
      font-size: clamp(20px, 2.8vw, 44px);
    }
  }
  .carousel-container-layout {
    height: 100vh;
    margin-bottom: 0;
  }
  .carousel-text-container {
    left: 7%;
    top: 40%;
    padding: 10px;
  }
  .carousel-text {
    font-size: 18px;
  }
}


// Map Styles are under the map.scss page
// Testimonials Styles are under the testimonianls.scss page