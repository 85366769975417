.button-wrapper {
  margin-top: 20px;
}

.btn {
  width: 300px;
  height: 40px;
  font-size: 24px;
  font-family: "Cabin", Arial, Helvetica, sans-serif;
  font-weight: lighter;
  border: none;
  cursor: pointer;
  &-orange {
    color: white;
    background-color: $site-color-light-orange;
  }
  &-cream {
    color: $site-color-green;
    background-color: $site-background;
  }
  &-green {
    background-color: $site-color-green;
    color: white;
  }
  &-brown {
    background-color: $site-color-brown;
    color: white;
  }
}