.map {
    &-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin-bottom: 80px;
        width: 100vw;
        position: relative;
        height: calc(150vh - 80px);
    }
    &-text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
    &-text {
        font-size: 40px;
        margin: 0;
        color: $site-color-green;
        font-weight: 100;
    }
    &-btn {
        margin-top: 25px;
        width: 250px;
    }
}


@media only screen and (max-width: 1025px){
    .map {
        &-wrapper {
        }
        &-text {
            font-size: 32px;
        }
    }
}

@media only screen and (max-width: 426px){
    .map {
        &-image {
            width: 90vw;
        }
        &-text {
            font-size: 22px;
        }
    }
}

@media only screen and (max-width: 376px){
    .map {
        &-text {
            font-size: 18px;
        }
    }
}