// These are for Project Home, Project Card, and Project Filter Bar

.projects-home-wrap {
  margin-top: 125px;
  margin-bottom: 50px;
}

.projects-header{
  color: #606C38;
  font-weight: 100;
  text-align: center;
  font-size: 50px;
  margin: 18px;
}

.projecs-filter-bar-wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 50px;
  background-color: #DDA15E;
  width: 100vw;
}

.projects-filter-text{
  margin: 0 15px 0 15px;
  padding-bottom: 3px;
  color: #606C38;
  font-size: 24px;
  cursor: pointer;
}



.selected{
  border-bottom: 1px solid #606C38;
}

.project-card-container {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  width: 100%;
}

.project-card-link-wrapper{
  height: 355px;
  width: 400px;
  margin: 25px;
  border: 1px solid #BC6C25;
}

.project-links {
  text-decoration: none;
}

.project-thumbnail-image {
  width: 100%;
  height: 225px;
}

.project-card-title{
  font-size: 24px;
  color: #606C38;
  margin: 0;
  font-weight: 100;
  text-align: center;
}
.project-card-description{
  font-size: 16px;
  color: #BC6C25;
  padding: 5px;
  margin: 0;
}

.truncated {
  display: block;
  line-height: 1.5em;
  height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.project-card-categories-wrapper{
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.project-card-tags{
  font-size: 18px;
  color: #606C38;
  margin: 0;
  margin-right: .3em;
}
.project-card-location{
  font-size: 12px;
  color: #606C38;
  font-style: italic;
  margin: 0;
  text-align: right;
  margin-right: 5px;
}

.project-card-edit-container {
  display: flex;
  justify-content: flex-end;
}



// These are for Project Detials Page

.project-details-outer-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 150px;
}

.project-details-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%
}

.project-details-left-wrapper{
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 30px;
}



.project-details-center-wrapper{
  width: 60%;
}

.project-details-right-wrapper{
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 30px;
}

.project-details-sub-header {
  color: #606C38;
  font-size: 28px;
  font-weight: 100;
}

.project-details-categories {
  font-size: 18px;
  color: #BC6C25;
  margin: 15px 0 15px 0;
}

.project-details-categories-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
}

.project-details-other-info-text {
  font-size: 18px;
  color: #BC6C25;
  margin: 15px;
}

.project-details-title-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.project-details-image {
  width: 50vw;
  height: auto;
}

.project-details-title {
  font-size: 32px;
  color: #606C38;
  font-weight: 100;
  margin: 0;
}

.project-details-location {
  font-size: 24px;
  color: #606C38;
  font-weight: 100;
  font-style: italic;
  margin: 0;
}

.project-details-description {
  font-size: 18px;
  color: #BC6C25;
}

.project-details-link {
  text-align: center;
  color: #606C38;
  margin-top: 25px;
  margin-bottom: 60px;
  font-size: 18px;
}


// These are for Edit Project

.project-edit-button {
  font-size: 30px; 
  cursor: pointer; 
  color: #606C38;
  margin-top: -15px;
}

.project-edit-modal {
  &-wrapper{
    background-color: $site-background;
  }
  &-close-btn-wrap {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  &-close-btn-icon {
    font-size: 28px;
    cursor: pointer;
    color: $site-color-orange;
  }
  &-section-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;
  }
  &-section-input {
    font-size: 16px;
    height: 20px;
    width: 300px;
    margin-left: 25px;
  }
  &-section-text-area {
    font-size: 16px;
    height: 200px;
    width: 300px;
    margin-left: 25px;
  }
  &-section-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 60%;
  }
  &-section-header {
    color: $site-color-green;
  }
  &-image-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
  }
  &-delete-wrapper {
    display: flex;
  }
  &-delete-btn {
    font-size: 24px;
    margin: 20px;
    color: red;
    cursor: pointer;
  }
}



@media only screen and (max-width: 769px){
  .project-details-outer-wrapper {
    margin-top: 150px;
  }
  .project-details-wrapper {
    flex-direction: column;
  }
  .project-details-left-wrapper{
    width: 80%;
  }
  .project-details-center-wrapper{
    width: 80%;
  }
  .project-details-right-wrapper{
    width: 80%;
  }
  .project-details-sub-header {
    margin-bottom: 10px;
    margin-top: 0;
  }
  .project-details-categories-wrapper {
    flex-direction: row;
    justify-content: space-around;
  }
  .project-edit-modal {
    &-section-wrap {
      width: 100%;
    }
    &-section-btn-wrap {
      width: 100%;
    }
  }
}


// This is for The Create New Project Page

.project-create {
  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 150px;
  }
  &-section-header {
    color: $site-color-green;
  }
  &-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 25px;
    width: 80%;
    input {
      width: 400px;
      font-size: 18px;
      padding: 5px;
      margin: 10px;
      border: none;
    }
    textarea {
      width: 400px;
      height: 200px;
      font-size: 18px;
      padding: 5px;
      margin: 10px;
      border: none;
    }
  }
  &-image-container {
    display: flex;
    flex-wrap: wrap;
  }
  &-create-btn {
    margin-top: 10px;
    margin-bottom: 100px;
  }
  &-category-btn {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 426px){
  .projects-home-wrap {
    margin-top: 75px;
  }
  .projects-header{
    font-size: 36px;
  }
  .projecs-filter-bar-wrapper {
    flex-direction: column;
    height: 175px;
  }
  .projects-filter-text{
    margin: 3px;
    font-size: 18px;
  }
  .project-details-title-container {
    flex-direction: column;
  }
  .project-edit-modal {
    &-section-wrap {
      flex-direction: column;
    }
    &-section-input {
      margin-left: 0;
    }
    &-section-text-area {
      margin-left: 0;
    }
    &-section-btn-wrap {
      margin-top: 10px;
    }
  }
}
